.label-col {
    height: 20px;
    width: 4vw;
}

.value-col {
    height: 20px;
    width: 8.5vw;
    flex-grow: 3;
}

.border-goal {
    border: 2px solid var(--ion-color-primary);
    text-align: center;
    min-width: 80px;
    border-radius: 10px;
    margin-right: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.task-preview-modal {
    --width: 800px;
    --height: 400px;
}
.free-text-task-preview-modal {
    --width: 800px;
    --height: 500px;
}
.task-preview-full-information-modal {
    --width: 800px;
    --height: 600px;
}

.exercise-session-user-state-select-option{
    width: 10.938rem;
    border: 0.063rem solid;
}
