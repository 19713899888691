.relativ-horizontal-padding {
    padding-left: 6% !important;
    padding-right: 6% !important;
}

.fixed-horizontal-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.fixed-small-padding-bottom {
    padding-bottom: 4px !important;
}
