.red {
    color: var(--ion-color-danger);
}

.yellow {
    color: var(--ion-color-warning);
}

.green {
    color: var(--ion-color-success);
}

.flag-icon {
    font-size: 0.8rem;
}
