@media (max-width: 750px) {
    .item-label-user-info {
        flex-basis: 20%;
        min-width: 33vw !important;
        max-width: 8em;
        font-weight: bold;
    }
}

.label-icon {
    margin-left: 5px;
}
