/**
Global button
*/

.btn-height-responsive {
    height: 12vh;
    font-size: x-large;
    width: 100%;
}

.btn-medium-height-responsive {
    height: 8vh;
    font-size: large;
    width: 100%;
}

.btn-font-medium {
    width: 13rem;
    max-width: 100%;
    white-space: pre;
    text-wrap: balance;
}

.btn-action {
    width: 13rem;
    max-width: 100%;
    white-space: pre;
}

.btn-medium {
    width: 15rem;
    max-width: 100%;
}

// button style to form component
.button-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100% !important;
    flex-direction: row;
    margin-top: 1rem;
}

@media (max-width: 750px) {
    .btn-font-medium {
        min-width: 33%;
        max-width: 50%;
        white-space: pre;
        text-wrap: balance;
    }

    .btn-action {
        width: 40%;
    }
}

.segment-button {
    width: 104px;
    max-width: 200px;
    --padding-end: 0;
    --padding-start: 0;
    --margin-start: 0;
    --margin-end: 2px;
    margin-right: 3px;
    --padding-bottom: 3%;
}

.ios-button {
    --color: var(--ion-color-primary-contrast);
    --color-checked: var(--ion-color-primary);
    --color-focused: var(--ion-color-primary-contrast);
    --color-hover: var(--ion-color-primary-contrast);
    white-space: pre-wrap;
    font-size: 14px;
    --padding-start: 10px;
    --padding-end: 10px;
}

.segment-button-with-icon {
    height: 95px;
    min-height: 95px;
}

.segment-button-without-icon {
    height: 65px;
}

.cart-badge {
    position: absolute;
    top: 0;
    right: 0;
}

// Classes von MIA kopiert:
/**
Global button
*/
.btn-font-normal {
    width: 10rem;
}

.font-button-MIA {
    font-size: 18px;
}

.font-medium-responsive {
    font-size: 1.3em;
    //font-size: 2vh
}

.modal-footer-btn {
    width: 10rem;
    max-width: 100%;
    white-space: pre;
    text-wrap: balance;
}

.content-button-text {
    opacity: 0.55;
    color: black;
    font-size: 1rem;
}
.content-button-text-underline {
    display: inline-block;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    color: var(--ion-color-primary);
    opacity: 1;
}

