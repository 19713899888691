// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    --ion-color-success: #1aaa55;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #168f48;
    --ion-color-success-tint: #28e070;

    --ion-color-warning: #fbe33c;
    --ion-color-warning-rgb: 251, 227, 60;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #ddc835;
    --ion-color-warning-tint: #fbe650;

    --ion-color-danger: #e73440;
    --ion-color-danger-rgb: 231, 52, 64;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cb2e38;
    --ion-color-danger-tint: #e94853;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #696969;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #eeeeee;
    --ion-color-light-rgb: 238, 238, 238;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d3d3d3;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-beta: #54b4e2;
    --ion-color-beta-rgb: 84, 180, 226;
    --ion-color-beta-contrast: #000000;
    --ion-color-beta-contrast-rgb: 0, 0, 0;
    --ion-color-beta-shade: #4a9ec7;
    --ion-color-beta-tint: #65bce5;

    --ion-color-dev: #b8f402;
    --ion-color-dev-rgb: 184, 244, 2;
    --ion-color-dev-contrast: #000000;
    --ion-color-dev-contrast-rgb: 0, 0, 0;
    --ion-color-dev-shade: #a2d702;
    --ion-color-dev-tint: #bff51b;

    --ion-color-demo: #f1ce05;
    --ion-color-demo-rgb: 241, 206, 5;
    --ion-color-demo-contrast: #000000;
    --ion-color-demo-contrast-rgb: 0, 0, 0;
    --ion-color-demo-shade: #d4b504;
    --ion-color-demo-tint: #f2d31e;

    --ion-color-card: #c2d5f1;
    --ion-color-card-rgb: 194, 213, 241;
    --ion-color-card-contrast: #000000;
    --ion-color-card-contrast-rgb: 0, 0, 0;
    --ion-color-card-shade: #abbbd4;
    --ion-color-card-tint: #c8d9f2;

    --ion-toolbar-background-color: #1179c8;
    --curafida-wrapper-width: 800px;
    --curafida-login-wrapper-width: 800px;
    --curafida-wrapper-mobile-width:400px;
}

.ion-color-beta {
    --ion-color-base: var(--ion-color-beta) !important;
    --ion-color-base-rgb: var(--ion-color-beta-rgb) !important;
    --ion-color-contrast: var(--ion-color-beta-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-beta-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-beta-shade) !important;
    --ion-color-tint: var(--ion-color-beta-tint) !important;
}

.ion-color-dev {
    --ion-color-base: var(--ion-color-dev) !important;
    --ion-color-base-rgb: var(--ion-color-dev-rgb) !important;
    --ion-color-contrast: var(--ion-color-dev-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-dev-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-dev-shade) !important;
    --ion-color-tint: var(--ion-color-dev-tint) !important;
}

.ion-color-demo {
    --ion-color-base: var(--ion-color-demo) !important;
    --ion-color-base-rgb: var(--ion-color-demo-rgb) !important;
    --ion-color-contrast: var(--ion-color-demo-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-demo-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-demo-shade) !important;
    --ion-color-tint: var(--ion-color-demo-tint) !important;
}

.ion-color-card {
    --ion-color-base: var(--ion-color-card) !important;
    --ion-color-base-rgb: var(--ion-color-card-rgb) !important;
    --ion-color-contrast: var(--ion-color-card-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-card-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-card-shade) !important;
    --ion-color-tint: var(--ion-color-card-tint) !important;
}
