// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '../../../libs/common/css/common.scss';
@import '../../../libs/therapy/css/therapy-lib-css';
@import '../../../libs/kiosk/css/kiosk-lib-css';

ion-card,
p,
ion-label,
div {
  font-size: var(--curafida-text-size) !important;
}

ion-button {
  text-transform: initial;
  white-space: pre-wrap;
}

.fine-granular-grid {
  --ion-grid-columns: 24;
}

/*.modal-wrapper {
  width: calc(3 * var(--curafida-wrapper-width) / 4) !important;
  min-height: 200px !important;;
  max-height: 700px !important;;
}*/

/// Alert Setting
.sc-ion-alert-md-h {
  --max-width: 400px !important;
  --max-height: 250px !important;
  border-radius: 1px;
}

.alert-head.sc-ion-alert-md,
.alert-head.sc-ion-alert-ios {
  background: var(--ion-color-primary);
  border-radius: 1px 1px 0 0;
}

.alert-title.sc-ion-alert-md,
.alert-title.sc-ion-alert-ios {
  color: var(--ion-text-color, #fff);
}

.alert-input-group.sc-ion-alert-md,
.alert-message.sc-ion-alert-md {
  padding: 0 24px 24px;
  color: var(--ion-color-dark-tint);
}

.alert-message.sc-ion-alert-md,
.alert-message.sc-ion-alert-ios {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  padding: 12px !important;
  font-size: 20px !important;
  font-weight: 700;
}

.text-small {
  font-size: small !important;
}

.form-input:first-letter {
  text-transform: uppercase;
}

.form-input {
  padding-left: 0;
  width: 50%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.label-item {
  padding-left: 0;
  width: 10rem !important;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

ion-header {
  background: var(--ion-color-primary) !important;
}

.split-pane-md.split-pane-visible > .split-pane-side {
  min-width: 150px;
  max-width: 250px !important;
}

.item-bottom {
  border-bottom: 2px solid var(--ion-color-light);
}

.modal-auto-height .modal-wrapper {
  height: auto;
  min-height: 350px;
}

.norm-values-modal .modal-wrapper {
  height: auto;
  min-height: 550px;
}

.modal-table-container {
  margin: 10px;
}

// Form for Edge
.label-floating.sc-ion-label-md-h {
  color: var(--ion-color-dark) !important;
}

.modal-date-picker {
  --min-width: 300px;
  --min-height: 300px;
  padding: 30px !important;
}

.modal-standard {
  --width: 500px;
  --height: 300px;
}

.popover-date-picker {
  --width: 400px;
  --height: 300px;
}

.modal-list {
  --width: var(--curafida-wrapper-width);
  --height: 740px;
}

.modal-exercise-detail {
  --width: var(--curafida-wrapper-width);
  --height: 500px;
}

:root {
  --slider-training-height: 30vh !important;
}

app-kiosk-home-page {
  height: 100%;
  padding: 2px;

}