/**
Global mobile
*/

.mobile-title {
    font-weight: bold;
    padding-bottom: 5px;
}

.mobile-font {
    font-size: 1rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: anywhere;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

@media (max-width: 750px) {
    .wrapper {
        //background-color: white;
    }

    .form-input {
        padding-left: 5px;
        padding-right: 5px;
        width: 90% !important;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        text-transform: initial;
    }

    .foot-wrapper {
        position: fixed;
        max-width: var(--curafida-wrapper-width);
        //padding: 10px 50px;
        left: 0;
        z-index: 5;
        bottom: 0;
    }

    .form-button {
        padding-left: 0;
        width: 80% !important;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
    }

    .page-title {
        font-size: 1.2rem;
        padding-bottom: 1vh;
        padding-top: 2vh;
        border-bottom: 1px solid var(--ion-color-primary);
    }

    .web {
        display: none !important;
    }

    .dashboard-btn {
        padding: 0.5rem;
        max-width: 40vw;
        max-height: 40vw;
        min-width: 8vw;
        min-height: 10vw;
        box-shadow: #5b6a79;
        flex-grow: 1;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .dashboard-icon {
        font-size: 20vw;
    }

    .wrapper-without-height-login {
        margin-left: auto;
        margin-right: auto;
        padding: 0 !important;
        --padding: 0 !important;
        max-width: var(--curafida-wrapper-width);
    }

    .sc-ion-popover-md {
        --width: 100%;
        padding: 1px;
        left: 0 !important;
    }

    .sc-ion-popover-ios {
        --width: 100%;
        padding: 1px;
        left: 0 !important;
    }

    lib-curafida-page-title {
        display: none !important;
    }
    .web-width {
        width: 95%;
    }
}
