.ion-color-beta {
    --ion-color-base: var(--ion-color-beta) !important;
    --ion-color-base-rgb: var(--ion-color-beta-rgb) !important;
    --ion-color-contrast: var(--ion-color-beta-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-beta-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-beta-shade) !important;
    --ion-color-tint: var(--ion-color-beta-tint) !important;
}

.ion-color-dev {
    --ion-color-base: var(--ion-color-dev) !important;
    --ion-color-base-rgb: var(--ion-color-dev-rgb) !important;
    --ion-color-contrast: var(--ion-color-dev-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-dev-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-dev-shade) !important;
    --ion-color-tint: var(--ion-color-dev-tint) !important;
}

.ion-color-demo {
    --ion-color-base: var(--ion-color-demo) !important;
    --ion-color-base-rgb: var(--ion-color-demo-rgb) !important;
    --ion-color-contrast: var(--ion-color-demo-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-demo-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-demo-shade) !important;
    --ion-color-tint: var(--ion-color-demo-tint) !important;
}

.ion-color-primary-inverse {
    --ion-color-base: var(--ion-color-primary-inverse) !important;
    --ion-color-base-rgb: var(--ion-color-primary-inverse-rgb) !important;
    --ion-color-contrast: var(--ion-color-primary-inverse-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-primary-inverse-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-primary-inverse-shade) !important;
    --ion-color-tint: var(--ion-color-primary-inverse-tint) !important;
}

.ion-color-card {
    --ion-color-base: var(--ion-color-card) !important;
    --ion-color-base-rgb: var(--ion-color-card-rgb) !important;
    --ion-color-contrast: var(--ion-color-card-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-card-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-card-shade) !important;
    --ion-color-tint: var(--ion-color-card-tint) !important;
}
