.swiper-pagination-bullet-active {
    background-color: var(--ion-color-primary);
}
.swiper-pagination{
    border-radius: 5px !important;
    background-color: #000000 !important;
    color: #FFFFFF !important;
    position: absolute !important;
    top: 2rem !important;
    padding-top: 2px !important;
    text-align: center!important;
    transition: .3s opacity !important;
    transform: translate3d(0, 0, 0)!important;
    z-index: 10!important;
}
.swiper-pagination-fraction{
    border-radius: 5px !important;
    background-color: #000000 !important;
    color: #FFFFFF !important;
    position: absolute !important;
    top: 2rem !important;
    padding-top: 2px !important;
    text-align: center!important;
    transition: .3s opacity !important;
    transform: translate3d(0, 0, 0)!important;
    z-index: 10!important;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 80%;
    left: 88%;
    width: 10%;
}
