/**
Global flex
*/
@media (min-width: 901px) {
    .flex-container-row-mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .flex-item {
        width: 50%;
    }
}

@media (max-width: 750px) {
    .flex-container-row-mobile {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .flex-item {
        width: 100%;
    }
}

.flex-container-row-end {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-container-column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-container-column-reverse {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.horizontal-center {
    justify-content: center !important;
}

.full-width {
    width: 100%;
}

.full-width-relative {
    display: flex;
    width: 100%;
    position: relative;
}

.half-width {
    width: 50% !important;
}

// Half-width case, aligned to the right
.half-width-right {
    width: 50%;
    margin-left: auto;
}

.third-width {
    width: 33% !important;
}

.two-thirds-width {
    width: 66% !important;
}

.full-height {
    height: 100% !important;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.vertical-center {
    align-items: center !important;
}

.vertical-stretch {
    align-items: stretch !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.justify-content-flex-start {
    justify-content: flex-start;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.align-items-flex-start {
    align-items: flex-start !important;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-stretch {
    align-items: stretch;
}

.scroll-enable {
    overflow: scroll;
}

// Array flex
.container-array-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    text-transform: none !important;
}

.container-array-row-list {
    padding-left: 2vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.container-array-row-item {
    padding-left: 3vw !important;
}

.container-array-row-item-justify-end {
    justify-content: flex-end !important;
}

.self-align-end {
    align-self: flex-end;
    align-items: flex-end;
}

.self-align-center {
    align-self: center;
}

.self-align-start {
    align-self: flex-start;
    align-items: flex-start;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-start {
    --padding-start: 0;
}

.no-padding-end {
    --padding-end: 0;
}

.text-align-center {
    text-align: center;
}

.text-align-start {
    text-align: start;
}

.text-align-end {
    text-align: end;
}
