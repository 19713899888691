.icon {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 10px;
    fill: var(--ion-color-primary);
    stroke: var(--ion-color-primary);
    flex-shrink: 0;
    border-radius: 50%;
}

.timeline {
    list-style: none;
    padding: 1px 0 1px;
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 2px;
    background-color: var(--ion-color-primary);
    margin-left: 1.5px;
}

.timeline > li {
    position: relative;
}

.timeline > li:before,
.timeline > li:after {
    content: ' ';
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: ' ';
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    width: 97%;
    float: left;
    border: 2px solid #d4d4d4;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-day {
    width: 100px;
    height: 30px;
    line-height: 26px;
    font-size: 14px !important;
    text-align: center;
    position: absolute;
    top: 0;
    margin-left: -13px;
    z-index: 100;
    border: 2px solid var(--ion-color-primary);
    border-radius: 8px;
    background: white;
    margin-top: -40px;
}

.timeline > li.timeline-inverted-day {
    margin-top: 60px;
}

.timeline > li > .timeline-badge {
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 35px;
    //font-size: 1.5em;
    text-align: center;
    position: absolute;
    top: 16px;
    margin-left: -13px;
    background-color: #ffffff;
    z-index: 100;
    border-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}

.timeline-badge.primary {
    background-color: var(--ion-color-primary) !important;
}

.timeline-badge.success {
    background-color: var(--ion-color-success) !important;
}

.timeline-badge.warning {
    background-color: var(--ion-color-warning) !important;
}

.timeline-badge.danger {
    background-color: var(--ion-color-danger) !important;
}

.timeline-badge.info {
    background-color: var(--ion-color-medium) !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 10px;
    }

    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline > li > .timeline-badge {
        left: -2px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

    ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }

    .timeline > li > .timeline-badge {
        top: 50px;
    }
}

ul.timeline {
    margin-block-start: 0 !important;
}

.add-button {
    position: absolute;
    text-align: end;
}

.no-box-shadow {
    box-shadow:
        rgba(0, 0, 0, 0) 0 3px 1px -2px,
        rgba(0, 0, 0, 0) 0 2px 2px 0,
        rgba(0, 0, 0, 0) 0 1px 5px 0 !important;
}
